import { sha256 } from '~/utils/hash'

export default {
  methods: {
    sendAnalyticsClickEvent(enableTracking, ctaLabel) {
      if (enableTracking && !this.$config.isE2E) {
        window.dataLayer = window.dataLayer || []
        // eslint-disable-next-line no-undef
        dataLayer.push({
          event: 'ctaClick',
          ctaLabel,
        })
      }
    },
    async sendAnalyticsFormEvent(emailAddress) {
      if (emailAddress && !this.$config.isE2E) {
        const hashedEmail = await sha256(emailAddress)

        window.dataLayer = window.dataLayer || []
        // eslint-disable-next-line no-undef
        dataLayer.push({
          event: 'formSubmitContact',
          email_sha256: hashedEmail,
        })
      }
    },
  },
}
