
import linkResolver from '~/app/prismic/link-resolver'
import trackingMixin from '~/mixins/tracking'

export const linkTypes = {
  document: 'Document',
  web: 'Web',
  media: 'Media',
}

export default {
  mixins: [trackingMixin],
  props: {
    field: {
      type: Object,
      required: true,
      default: () => {},
    },
    enableTracking: {
      type: Boolean,
      required: false,
      default: false,
    },
    ctaLabel: {
      type: String,
      required: false,
      default: '',
    },
    anchorId: {
      type: String,
      required: false,
      default: '',
    },
  },
  computed: {
    componentAttributes() {
      switch (this.field.link_type) {
        case linkTypes.document:
          return {
            field: { ...this.field, anchorId: this.anchorId },
            linkResolver,
          }
        case linkTypes.media:
          return {
            href: this.field.url,
            download: 'download',
            target: '_blank',
          }
        case linkTypes.web:
        default:
          return {
            href: this.field.url,
            target: '_blank',
          }
      }
    },
    hasLinkType() {
      // link_type has 'Any' value when no value was setup
      return this.field.link_type ? this.field.link_type !== 'Any' : false
    },
    attrs() {
      return {
        ...this.$attrs,
        ...this.componentAttributes,
      }
    },
    component() {
      switch (this.field.link_type) {
        case linkTypes.document:
          return 'prismic-link'
        case linkTypes.media:
        case linkTypes.web:
        default:
          return 'a'
      }
    },
    linkRefElement() {
      const el = this.$refs?.linkElement?.$el

      if (!el) {
        return this.$refs?.linkElement
      }

      return el
    },
  },
  methods: {
    navigateToLink(event) {
      this.sendAnalyticsClickEvent(this.enableTracking, this.ctaLabel)

      if (this.linkRefElement) {
        // Adding an event with stop propagation
        // so the click trigger doesn't trigger the analytics twice
        const listener = (e) => {
          e.stopPropagation()
        }

        this.linkRefElement.addEventListener('click', listener)
        this.linkRefElement.click()
        // remove just in case something goes wrong and the user doesn't navigate away
        // so we don't add have 2 listeners on the next click
        this.linkRefElement.removeEventListener('click', listener)
      }
    },
  },
}
